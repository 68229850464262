.faq {
  .questions-list {
    .question {
      margin-top: 40px;
    }
  }
  
  .question>p:first-child {
    margin-bottom: 0;
  }
  
  span.tag {
    color: white;
  }
  
  span.delete {
    text-decoration: underline;
    color: gray;
    padding-left: 4px;
  }
}