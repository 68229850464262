.letter {
  height: 100vh;
}

span.invite {
  text-decoration: underline;
  color: white;
  padding-left: 4px;
  cursor: pointer;
}

.invite-modal .modal-content {
  background-image: url('/static_content/back.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 400px;


  & > div {
    width: 50%;
    margin-top: 75px;
    
    margin-left: auto;
    margin-right: 0;
    
    input {
      width: 90%;
    }
    
    span.invite {
      float:right;
      margin-right: 16px;
      color: black;
    }
  }
}

.fit-image{
  width: 100%;
  object-fit: cover;
}