@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Sarcasm Ink';
  font-style: normal;
  font-weight: normal;
  src: url("fnt/SarcasmInk.otf") format("opentypw"), url("fnt/SarcasmInk.ttf") format("truetype");
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  z-index: 100;
}

body {
  background-color: #fac838 !important;
  --bs-body-font-family: Sarcasm Ink, serif;
  --bs-body-font-size: 1.5em;
  --bs-body-font-weight: bold;

  .page-content {
    width: 100%;
    a, .highlight {
      color: white !important;
    }
    
    .large-logo {
      z-index: -5;
      padding-top: 70px;
      width: 60%;
      
      @media (min-width: 1200px) {
        position: fixed;
        width: 15%;
        
        left: 20px;
        top: 20px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;

      min-height: 100vh;
      width: 100%;

      &:nth-child(2) {
        margin-top: 70px;
      }

    }

  }
}

a {
  text-decoration: none;
}

p.big {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.1em;
  max-width: 110rem;
  margin: 2rem auto 0 auto;
  padding: 0 0.5em;
  text-align: center;
}
