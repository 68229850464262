header {
  position: fixed;
  width: 100%;

  .navbar-nav > a {
    padding-left: 24px !important;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("/img/menu-button.png");
  }

  a.active:not(.nav-logo) {
    background-image: url('/img/menu-highlight.png');
    background-size: 120px;
    background-position: left;
    background-repeat: no-repeat;
  }
}

#tsparticles {
  position: fixed;
  z-index: -5;
  top: 0;

  height: 100vh;
  width: 100%;
}

$width: 15px;
$height: 15px;

$bounce_height: 30px;

.loading {
  .text {
    color: black;
    display: inline-block;
    margin-left: 5px;
  }

  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: $width;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: $width;
      height: $height;
      border-radius: 50%;
      background-color: white;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
    }
  }

  @keyframes bounce {
    0% {
      top: $bounce_height;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }
    35% {
      height: $height;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
  }
}